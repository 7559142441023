<template>
      <Spinner v-if="loading"/>
<div class="tab" >
        <button style="flex-grow: 4;font-size: 15px" v-bind:class="{tabactive: showChats}" @click="setChats()">Chats</button>
        <button style="font-size: 15px" v-bind:class="{tabactive: showNew}" @click="setNew()">Neuer Chat</button>
    </div>
    <div v-if="showChats" style="padding: 3px">
        <template v-for="chat in chats" :key="chat.id">
            <div class="job" style="padding: 5px;display: block" :onclick="'window.location=\'/chat/'+chat.id+'\''">
                <span style="font-size: 20px; font-weight: bold">{{chat.name}}</span>
                <div class="detailrow" v-bind:class="{unread: !chat.read}">
                    <i v-if="chat.incoming" style="color: black" class="fas fa-arrow-left"></i>
                    <i v-else style="color: black" class="fas fa-arrow-right"></i>
                    <span style="margin-left: 10px;font-weight: bold">{{chat.last}}</span>
                    <div style="margin-top: 10px;height: 1px;background:grey;width: 100%"></div>
                </div>
            </div>
        </template>
    </div>
    <div v-if="showNew">
        <div class="job" style="padding: 15px;display: block">
          <div v-if="error">
            <h3 style="color: red;">{{error}}</h3>
          </div>
            <div style="margin-top: 15px; margin-bottom: 15px">
                <div>
                    <span style="font-weight: bold">Kontakt:</span>
                </div>
                <input v-model="contact" style="width: 100%; box-sizing: border-box;"
                    list="chatContacts">
                <datalist id="chatContacts">
                    <template v-for="contact in chatContacts" :key="contact">
                        <option>{{contact}}</option>
                    </template>
                </datalist>
            </div>
            <div style="margin-top: 15px; margin-bottom: 15px;">
                <span style="display: inline-block;font-weight: bold;margin-bottom: 5px">Nachricht:</span>
                <div>
                    <textarea v-model="newMessage"
                        style="padding: 5px; box-sizing: border-box; width: 100%;" rows="1" @focus="resizeTextarea" @keyup="resizeTextarea"></textarea>
                </div>
            </div>
            <div style="width: 100%; text-align: left">
      <button style="display: inline-block; color: rgb(0,122,255); border: 3px solid rgb(0,122,255); border-radius: 8px; font-weight: bold;font-size: 18px; width: 40%;background-color:white" @click="createChat">Senden</button>
        </div></div>
    </div>
</template>
<script>
import CASP from '../CASP'
import Spinner from '@/components/Spinner'
import { useCookies } from 'vue3-cookies'

export default {
  setup () {
    const { cookies } = useCookies()
    return { cookies }
  },
  name: 'Jobs',
  components: {
    Spinner
  },
  data () {
    return {
      showChats: true,
      showNew: false,
      chatContacts: [],
      chats: [],
      token: '',
      newMessage: '',
      contact: '',
      error: false
    }
  },
  methods: {
    hideAll () {
      this.showChats = false
      this.showNew = false
    },
    setNew () {
      this.hideAll()
      this.showNew = true
    },
    setChats () {
      this.hideAll()
      this.showChats = true
    },
    resizeTextarea (e) {
      const area = e.target
      area.style.overflow = 'hidden'
      area.style.height = area.scrollHeight + 'px'
    },
    createChat () {
      const comp = this
      CASP.createChat(this.token, this.contact, this.newMessage, (response) => {
        if (response.success) {
          window.location = '/chat/' + response.chatId
        } else {
          comp.error = response.error
        }
      })
    }
  },
  created: function () {
    document.getElementById('mainspinner').style.display = 'none'
    if (this.cookies.get('authtoken')) {
      this.token = this.cookies.get('authtoken')
    } else {
      window.location = '/forbidden'
    }
    const comp = this
    CASP.getChats(this.token, (chats) => {
      comp.chats = chats
    })
    CASP.getChatContacts(this.token, (chatContacts) => {
      comp.chatContacts = chatContacts
    })
  }
}

</script>
<style scoped>
.tab {
  overflow: hidden;
  background-color: white;
  display: flex;
}

input:focus {
  outline: none;
  border: unset;
  border-bottom: 2px solid rgb(0, 122, 255)
}

input, textarea{
  border: unset;
    outline: none;
    border-bottom: 1px solid black;
}

textarea:focus{
  border-bottom: 1px solid rgb(0,122,255);
  outline: none;
}

.small{
flex-grow: 2;
}

.large{
flex-grow: 1;
}

button {
  font-weight: bold;
}
.unread {
  color: rgb(160, 0, 0)

}
/* Style the buttons that are used to open the tab content */
button {
  color: gray;
  border: none;
  outline: none;
  cursor: pointer;
  background: inherit;
  padding: 14px 16px;
  transition: 0.3s;
  flex-grow: 1;
  border-bottom: 3px solid gray ;
}

/* Change background color of buttons on hover
.tab button:hover {
  background-color: rgb(37, 37, 37);
}*/

/* Create an active/current tablink class */
.tabactive{
  color: rgb(0, 122, 255);
  border-bottom: 3px solid rgb(0, 122, 255);
}

/* Style the tab content */
.tabcontent {
  padding: 6px 12px;
  border: 1px solid #ccc;
  border-top: none;
}
</style>
