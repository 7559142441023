<template>
  <div class="job" style="padding: 5px">
    <div class="column a">
      <span style="font-size: 20px; font-weight: bold">{{ job.title }}</span>
      <div class="detailrow">
        <i class="far fa-calendar-alt fa-xl" style="padding-right: 5px"></i
        ><span style="font-weight: bold">{{ job.date }}</span>
      </div>
      <div class="detailrow">
        <i class="far fa-clock fa-xl" style="padding-right: 5px"></i>
        <span v-if="job.timeConfirmed" style="font-weight: bold">{{
          job.time
        }}</span>
        <div v-else>
          <input id="{{job.id}}" type="hidden" />
          <input
            :placeholder="job.time"
            v-model="value"
            style="width: 13ch"
          />
          <span style="padding-left: 5px; color: red">{{ error }}</span>
          <span style="padding-left: 5px; color: green">{{ success }}</span>
        </div>
      </div>
      <div class="detailrow">
        <i class="fas fa-map-marker-alt fa-xl" style="padding-right: 5px"></i
        ><span style="font-weight: bold">{{ job.location }}</span>
      </div>
    </div>
    <div class="column b">
      <i
        v-if="job.timeConfirmed"
        class="far fa-check-circle fa-2x"
        style="
          flex-grow: 1;
          color: green;
          display: table-cell;
          padding-right: 15px;
        "
      />
      <i
        v-else
        class="far fa-save fa-2x"
        style="
          cursor: pointer;
          flex-grow: 1;
          color: black;
          display: table-cell;
          padding-right: 15px;
        "
        @click="save()"
      />
    </div>
  </div>
</template>

<script>
import CASP from '../CASP'
import { useCookies } from 'vue3-cookies'

export default {
  setup () {
    const { cookies } = useCookies()
    return { cookies }
  },
  name: 'Archivejob',
  props: {
    job: Object
  },
  data () {
    return {
      value: '',
      error: '',
      success: ''
    }
  },
  methods: {
    msg (msg, error = false) {
      if (error) {
        this.error = msg
        this.success = ''
      } else {
        this.success = msg
        this.error = ''
      }
    },
    save () {
      if (this.value.includes('-')) {
        const parts = this.value.split('-')
        if (parts.length === 2) {
          const von = parts[0].trim()
          const bis = parts[1].trim()
          if (
            von.match(/^[0-9]?[0-9]:[0-9][0-9]$/) &&
            bis.match(/^[0-9]?[0-9]:[0-9][0-9]$/)
          ) {
            if (parseInt(von.split(':')[0]) > 23 || parseInt(von.split(':')[1]) > 59 || parseInt(bis.split(':')[0]) > 23 || parseInt(bis.split(':')[1]) > 59) {
              this.msg('Eingabe falsch', true)
            } else {
              CASP.setAttendance(
                this.cookies.get('authtoken'),
                this.job.id,
                von,
                bis,
                (response) => {
                  if (response.status === 204) {
                    this.msg('Zeit gespeichert')
                  } else if (response.status === 400) {
                    this.msg(response.responseText, true)
                  } else {
                    this.msg('Da hat etwas nicht geklappt', true)
                  }
                }
              )
            }
          } else {
            this.msg('Zeitangabe falsch formatiert', true)
          }
        }
      } else {
        if (this.value.trim() === '') {
          this.msg('Das Feld ist leer', true)
        } else {
          this.msg('Zeitangabe falsch formatiert', true)
        }
      }
    }
  },
  created: function () {
    if (this.job.time !== 'XX:XX - XX:XX') {
      this.value = this.job.time
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.column {
  float: left;
}
.a {
  width: 80%;
}
.b {
  width: 20%;
  height: 100%;
  text-align: right;
  position: absolute;
  display: table;
  right: 0;
  top: 0;
  display: flex;
  align-items: center;
}
.job {
  display: table;
  position: relative;
  overflow: hidden;
  clear: both;
  content: "";
  width: 100%;
  vertical-align: center;
}
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
.detailrow {
  display: flex;
  align-items: center;
  padding-top: 5px;
  padding-bottom: 5px;
}
button {
  cursor: pointer;
  padding: 10px 14px;
  display: inline-block;
  color: rgb(52, 199, 89);
  border: 2px solid rgb(52, 199, 89);
  border-radius: 8px;
  font-weight: bold;
  font-size: 18px;
  width: 40%;
  background-color: white;
}
input {
  outline: none;
  border: unset;
  border-bottom: 2px solid rgb(0, 0, 0);
}
input:focus {
  outline: none;
  border: unset;
  border-bottom: 2px solid green;
}
</style>
