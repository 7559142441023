/* eslint-disable standard/no-callback-literal */

function getAllJobs (token, callback) {
  var req = new XMLHttpRequest()
  req.open('GET', process.env.VUE_APP_API_URL + '/jobs')
  req.setRequestHeader('authorization', token)
  req.addEventListener('load', function (event) {
    callback(JSON.parse(req.responseText))
  })
  req.send()
}

function getJobsInGroup (token, groupId, callback) {
  var req = new XMLHttpRequest()
  req.open('GET', process.env.VUE_APP_API_URL + '/jobs/groups/'+groupId)
  req.setRequestHeader('authorization', token)
  req.addEventListener('load', function (event) {
    callback(JSON.parse(req.responseText))
  })
  req.send()
}
function getChats (token, callback) {
  var req = new XMLHttpRequest()
  req.open('GET', process.env.VUE_APP_API_URL + '/chats')
  req.setRequestHeader('authorization', token)
  req.addEventListener('load', function (event) {
    callback(JSON.parse(req.responseText))
  })
  req.send()
}

function getChatContacts (token, callback) {
  var req = new XMLHttpRequest()
  req.open('GET', process.env.VUE_APP_API_URL + '/chats/contacts')
  req.setRequestHeader('authorization', token)
  req.addEventListener('load', function (event) {
    callback(JSON.parse(req.responseText))
  })
  req.send()
}

function getArchive (token, callback) {
  var req = new XMLHttpRequest()
  req.open('GET', process.env.VUE_APP_API_URL + '/jobs/archive')
  req.setRequestHeader('authorization', token)
  req.addEventListener('load', function (event) {
    callback(JSON.parse(req.responseText))
  })
  req.send()
}
function getJobById (token, id, callback) {
  var req = new XMLHttpRequest()
  req.open('GET', process.env.VUE_APP_API_URL + '/jobs/' + id)
  req.setRequestHeader('authorization', token)
  req.addEventListener('load', function (event) {
    callback(JSON.parse(req.responseText))
  })
  req.send()
}

function createChat (token, contact, message, callback) {
  var req = new XMLHttpRequest()
  req.open('POST', process.env.VUE_APP_API_URL + '/chats')
  req.setRequestHeader('content-type', 'application/json')
  req.setRequestHeader('authorization', token)
  req.addEventListener('load', function (event) {
    callback(JSON.parse(req.responseText))
  })
  req.send(JSON.stringify({
    contact: contact,
    message: message
  }))
}
function getChatById (token, id, callback) {
  var req = new XMLHttpRequest()
  req.open('GET', process.env.VUE_APP_API_URL + '/chats/' + id)
  req.setRequestHeader('authorization', token)
  req.addEventListener('load', function (event) {
    callback(JSON.parse(req.responseText))
  })
  req.send()
}

function sendMessageInChat (token, id, message, callback) {
  var req = new XMLHttpRequest()
  req.open('POST', process.env.VUE_APP_API_URL + '/chats/' + id)
  req.setRequestHeader('content-type', 'application/json')
  req.setRequestHeader('authorization', token)
  req.addEventListener('load', function (event) {
    return callback(req.status === 201)
  })
  req.send(JSON.stringify({
    message: message
  }))
}

function setAttendance (token, id, von, bis, callback) {
  var req = new XMLHttpRequest()
  req.open('PUT', process.env.VUE_APP_API_URL + '/jobs/' + id)
  req.setRequestHeader('content-type', 'application/json')
  req.setRequestHeader('authorization', token)
  req.addEventListener('load', function (event) {
    return callback(req)
  })
  req.send(JSON.stringify({
    ma_ist_von: von,
    ma_ist_bis: bis
  }))
}

function isAuthenticated (token, callback) {
  var req = new XMLHttpRequest()
  req.open('GET', process.env.VUE_APP_API_URL + '/user/auth')
  req.setRequestHeader('authorization', token)
  req.addEventListener('load', function (event) {
    callback(req.status === 200)
  })
  req.send()
}

function acceptJob (token, id, callback) {
  var req = new XMLHttpRequest()
  req.open('GET', process.env.VUE_APP_API_URL + '/jobs/' + id + '/accept')
  req.setRequestHeader('authorization', token)
  req.addEventListener('load', function (event) {
    callback(req.status, req.responseText)
  })
  req.send()
}

function acceptJobGroup (token, groupid, callback) {
  var req = new XMLHttpRequest()
  req.open('GET', process.env.VUE_APP_API_URL + '/jobs/groups/' + groupid + '/accept')
  req.setRequestHeader('authorization', token)
  req.addEventListener('load', function (event) {
    callback(req.status, req.responseText)
  })
  req.send()
}

function declineJob (token, id, callback) {
  var req = new XMLHttpRequest()
  req.open('GET', process.env.VUE_APP_API_URL + '/jobs/' + id + '/decline')
  req.setRequestHeader('authorization', token)
  req.addEventListener('load', function (event) {
    callback(req.status, req.responseText)
  })
  req.send()
}

function confirmJob (token, id, callback) {
  var req = new XMLHttpRequest()
  req.open('GET', process.env.VUE_APP_API_URL + '/jobs/' + id + '/confirm')
  req.setRequestHeader('authorization', token)
  req.addEventListener('load', function (event) {
    callback(req.status, req.responseText)
  })
  req.send()
}

module.exports = {
  getAllJobs: getAllJobs,
  getJobsInGroup: getJobsInGroup,
  getChats: getChats,
  getArchive: getArchive,
  sendMessageInChat: sendMessageInChat,
  getJobById: getJobById,
  getChatContacts: getChatContacts,
  setAttendance: setAttendance,
  createChat: createChat,
  getChatById: getChatById,
  isAuthenticated: isAuthenticated,
  acceptJob: acceptJob,
  acceptJobGroup: acceptJobGroup,
  declineJob: declineJob,
  confirmJob: confirmJob
}
