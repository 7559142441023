<template>
  <Spinner v-if="loading" />
  <div
    style="
      text-align: center;
      border-bottom: 2px solid black;
      background-color: white;
      color: black;
    " class="px-10"
  >
    <i
      style="position: absolute; left: 12px; margin-top: 8px; color: black"
      class="fas fa-arrow-left fa-lg"
      @click="back()"
    ></i>
    <span style="font-size: 25px; font-weight: bold">{{
      job.vertragsbeschreibung
    }}</span>
  </div>
  <div v-if="job.id" style="padding: 10px;">
    <div class="detailline">
      <span class="attributename">Position:</span
      ><span class="attributevalue">{{ job.position }}</span>
    </div>
    <div class="detailline">
      <span class="attributename">Funktion:</span
      ><span class="attributevalue">{{ job.funktion }}</span>
    </div>
    <div class="detailline">
      <span class="attributename">Einsatzort:</span
      ><span class="attributevalue">{{ job.einsatzort_beschreibung }}</span>
    </div>
    <div class="detailline">
      <span class="attributename">Einsatzstelle:</span
      ><span class="attributevalue">{{ job.einsatzstelle_beschreibung }}</span>
    </div>
    <div class="detailline">
      <span class="attributename">Datum:</span
      ><span class="attributevalue">{{ job.datum_von }}</span>
    </div>
    <div class="detailline">
      <span class="attributename">Uhrzeit:</span
      ><span class="attributevalue"
        >{{ job.ma_soll_von }}-{{ job.ma_soll_bis }}</span
      >
    </div>
    <div class="detailline">
      <span class="attributename">Treffpunkt:</span
      ><span class="attributevalue">{{ job.treffpunkt }}</span>
    </div>
    <div class="detailline">
      <span class="attributename">Sonstige Infos:</span
      ><span class="attributevalue">{{ job.sonstige_infos }}</span>
    </div>
    <div style="height: 2px; background: black; width: 100%"></div>
    <div
      style="
        padding: 15px;
        width: 100%;
        display: inline-block;
        text-align: center;
        box-sizing: border-box;
      "
    >
      <div
        style="
          padding: 2px;
          margin: 3px;
          font-weight: bold;
          border: 2px solid red;
        "
        v-if="showError"
      >
        <span>{{ errorMsg }}</span>
      </div>
      <div
        style="
          padding: 2px;
          margin: 3px;
          font-weight: bold;
          border: 2px solid green;
        "
        v-if="showSuccess"
      >
        <span>{{ successMsg }}</span>
      </div>
      <button
        v-if="job.auftragsgruppe != 1 && job.auftragsgruppe != 0"
        style="width: 100%; border: 2px solid gray; color: gray"
        disabled
      >
        Job kann nur in der Hauptansicht angenommen werden
      </button>
      <template v-else>
        <button
          v-if="showDecline"
          style="color: red; border: 2px solid red"
          @click="decline()"
        >
          Absagen
        </button>
        <button v-if="showAccept" @click="accept()">Annehmen</button>
        <div v-if="confirmOrDecline">
          <button
            style="color: red; border: 2px solid red; margin-right: 5px"
            @click="decline()"
          >
            Absagen
          </button>
          <button
            :class="{ disabled: confirmDisabled }"
            style="margin-left: 5px"
            @click="confirm()"
          >
            Bestätigen
          </button>
        </div>
      </template>
    </div>
  </div>
  <div
    v-else-if="!loading"
    style="width: 100%; text-align: center; padding: 20px; padding-top: 100px"
  >
    <span style="font-size: 25px; font-weight: bold"
      >Dieser Job ist bereits vergeben oder wurde gelöscht.</span
    >
  </div>
</template>

<script>
import CASP from "../CASP";
import Spinner from "@/components/Spinner";
import { useCookies } from "vue3-cookies";
export default {
  setup() {
    const { cookies } = useCookies();
    return { cookies };
  },
  name: "Jobdetails",
  components: {
    Spinner,
  },
  data() {
    return {
      loading: true,
      showAccept: false,
      confirmOrDecline: false,
      showDecline: false,
      showPrivate: true,
      showPublic: false,
      confirmDisabled: false,
      showError: false,
      showSuccess: false,
      errorMsg: "",
      successMsg: "",
      job: [],
      allJobs: [],
    };
  },
  methods: {
    getJob() {
      const comp = this;
      this.showAccept = false;
      this.showDecline = false;
      this.confirmOrDecline = false;
      CASP.getJobById(this.token, this.$route.params.jobid, (job) => {
        comp.job = job;
        comp.loading = false;
        if (job.id !== undefined) {
          const dp = job.datum_von.split(".");
          let startTime = new Date(
            dp[2] + "-" + dp[1] + "-" + dp[0] + "T" + job.ma_soll_von + ":00Z"
          );
          startTime = new Date(
            startTime.getTime() + startTime.getTimezoneOffset() * 60000
          );
          const now = new Date();
          console.log(now);
          console.log(startTime);
          if (now.getTime() < startTime.getTime()) {
            if (!job.ma_gebucht) {
              comp.showAccept = true;
            } else if (!job.ma_bestaetigt) {
              comp.confirmOrDecline = true;
              console.log(startTime.getTime() - now.getTime());
              if (startTime.getTime() - now.getTime() > 24 * 60 * 60 * 1000) {
                comp.confirmDisabled = true;
              }
            }
          }
        }
      });
    },
    back() {
      if (this.job.auftragsgruppe && this.job.auftragsgruppe != 1 && this.job.auftragsgruppe != 0) {
        window.location="/group/"+this.job.auftragsgruppe
      } else {
        window.location="/jobs"
      }
    },
    showErrorMessage(message) {
      this.showError = true;
      this.showSuccess = false;
      this.errorMsg = message;
      const comp = this;
      setTimeout(() => {
        comp.showError = false;
      }, 5000);
    },
    showSuccessMessage(message) {
      console.log("showcucessmaessage");
      this.showError = false;
      this.showSuccess = true;
      this.successMsg = message;
    },
    accept() {
      const comp = this;
      CASP.acceptJob(
        this.token,
        this.$route.params.jobid,
        (scode, response) => {
          if (scode === 200) {
            comp.showSuccessMessage("Du hast diesen Auftrag angenommen");
            comp.getJob();
          } else {
            this.showErrorMessage(response);
          }
        }
      );
    },
    decline() {
      const comp = this;
      CASP.declineJob(
        this.token,
        this.$route.params.jobid,
        (scode, response) => {
          if (scode === 200) {
            comp.showSuccessMessage("Du hast diesen Auftrag abgesagt");
            comp.getJob();
          } else {
            this.showErrorMessage(response);
          }
        }
      );
    },
    confirm() {
      if (this.confirmDisabled) {
        this.showErrorMessage(
          "Du kannst diesen Auftrag erst 24 Stunden vor Beginn bestätigen"
        );
      } else {
        const comp = this;
        CASP.confirmJob(
          this.token,
          this.$route.params.jobid,
          (scode, response) => {
            console.log(scode);
            if (scode === 200) {
              comp.showSuccessMessage("Du hast diesen Auftrag bestätigt");
              comp.getJob();
            } else {
              this.showErrorMessage(response);
            }
          }
        );
      }
    },
  },
  created: function () {
    document.getElementById("mainspinner").style.display = "none";
    if (this.cookies.get("authtoken")) {
      this.token = this.cookies.get("authtoken");
      this.getJob();
    } else {
      window.location = "/forbidden";
    }
  },
};
</script>
<style scoped>
.detailline {
  margin-top: 15px;
  margin-bottom: 15px;
  display: flex;
}

.attributename {
  display: inline-block;
  flex-grow: 1;
  flex-basis: 33.33%;
  font-weight: bold;
}

.attributevalue {
  border-bottom: 1px solid black;
  flex-grow: 2;
  flex-basis: 66.66%;
}

/* Style the buttons that are used to open the tab content */
button {
  cursor: pointer;
  padding: 10px 14px;
  display: inline-block;
  color: rgb(52, 199, 89);
  border: 2px solid rgb(52, 199, 89);
  border-radius: 8px;
  font-weight: bold;
  font-size: 18px;
  width: 40%;
  background-color: white;
}

.disabled {
  border: 1px solid gray;
  color: gray;
}
</style>
