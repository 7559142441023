<template>
  <div class="screencenter">
    <div class="spinner"></div>
  </div>
</template>

<script>
export default {
  name: 'Spinner'
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
