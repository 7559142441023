<template>
  <Spinner v-if="loading"/>
    <div style="overflow-x: hidden" ref="messages" id="3">
          <div
              style="position: fixed; width: 100%; height: 50px; text-align:center; border-bottom: 2px solid black; padding: 10px; background-color: white;color: black">
              <i style="position: absolute; left: 12px; margin-top: 8px;color: black" class="fas fa-arrow-left fa-lg"
                  onclick="window.location = '/chat'"></i>
              <span style="font-size: 25px; font-weight: bold">{{chat.name}}</span>
          </div>
        <div :style="'padding-bottom: '+padding + 'px;display: flex; padding-top: 50px;background: #EEEEEE'" id="4">
            <div style="padding: 10px;box-sizing: border-box;padding-bottom: 50px;flex-grow: 1;" id="5">
                <template v-for="message in chat.messages" :key="message.msg">
                    <div :class="{left: message.incoming,right: !message.incoming, unread: !message.read}"
                        style="box-sizing: border-box; margin-top: 20px;">
                        <div
                            :class="{msgleft: message.incoming,msgright: !message.incoming}" style="padding: 5px; display: inline-block; border: 1px solid lightgray;box-sizing: border-box">
                            <span>{{message.msg}}</span>
                        </div>
                    </div>
                </template>
            </div>
            <div
                style="background: white;border-top: 3px solid white; width: 100%; box-sizing: border-box; padding: 3px; position: fixed; bottom: 0px; padding-bottom: 10px; left: 0px;">
                <div style=" border-radius: 5px;  display: flex">
                    <textarea v-model="newMessage"
                        style="background: white; padding: 5px; box-sizing: border-box; flex-grow: 5; "
                        rows="1" @focus="resizeTextarea" @keyup="resizeTextarea"></textarea>
                    <div
                        style="flex-grow: 1; text-align: center;display: flex;align-items: center; justify-content: center">
                        <i @click="sendMessage" style="color: rgb(0,122,255);" class="far fa-paper-plane fa-lg"></i>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import CASP from '../CASP'
import Spinner from '@/components/Spinner'
import { useCookies } from 'vue3-cookies'
export default {
  setup () {
    const { cookies } = useCookies()
    return { cookies }
  },
  name: 'Chat',
  components: {
    Spinner
  },
  data () {
    return {
      loading: true,
      padding: 0,
      chat: {},
      token: '',
      newMessage: ''
    }
  },
  methods: {
    resizeTextarea (e) {
      const area = e.target
      area.style.overflow = 'hidden'
      area.style.height = area.scrollHeight + 'px'
      this.padding = (area.scrollHeight)
    },
    setPrivate () {
      this.hideAll()
      this.showPrivate = true
    },
    setPublic () {
      this.hideAll()
      this.showPublic = true
    },
    setAccepted () {
      this.hideAll()
      this.showAccepted = true
    },
    getMessages () {
      const comp = this
      CASP.getChatById(this.token, this.$route.params.chatid, (chat) => {
        comp.chat = chat
        comp.loading = false
        setTimeout(() => {
          comp.scrollToBottom()
        }, 100)
      })
    },
    sendMessage () {
      const comp = this
      console.log('Sending')

      CASP.sendMessageInChat(this.token, this.$route.params.chatid, this.newMessage, (success) => {
        console.log('got return')
        console.log(success)

        if (success) {
          comp.newMessage = ''
          comp.getMessages()
        }
      })
    },
    scrollToBottom () {
      console.log('scrolling')
      document.getElementsByTagName('HTML')[0].scrollTop = document.getElementsByTagName('HTML')[0].scrollHeight
    }
  },
  created: function () {
    document.getElementById('mainspinner').style.display = 'none'
    if (this.cookies.get('authtoken')) {
      this.token = this.cookies.get('authtoken')
    } else {
      window.location = '/forbidden'
    }
    const getMessages = this.getMessages
    getMessages()
    setInterval(() => {
      getMessages()
    }, 10000)
  }
}

</script>
<style scoped>

.right {
  text-align: right;
  margin-left: 30px;
}

.unread {
  color: rgb(160, 0, 0)
}

.left {
  text-align: left;
  margin-right: 30px;
}

.msgleft {
  background: white;
  border-radius: 5px;
  border-end-end-radius: 20px;
  border-start-end-radius: 20px;
}
.msgright {
  background: #83ff77;
  border-radius: 5px;
  border-start-start-radius: 20px;
  border-end-start-radius: 20px;
}

.tab {
  overflow: hidden;
  background-color: white;
  display: flex;
}

.small{
flex-grow: 2;
}

.large{
flex-grow: 1;
}

textarea{
  border: unset;
    outline: none;
    border-bottom: 1px solid black;
}

textarea:focus{
  border-bottom: 1px solid rgb(0,122,255);
  outline: none;
}

button {
  font-weight: bold;
}
/* Style the buttons that are used to open the tab content */
button {
  color: black;
  border: none;
  outline: none;
  cursor: pointer;
  background: inherit;
  padding: 14px 16px;
  transition: 0.3s;
  flex-grow: 1;
}

/* Create an active/current tablink class */
.tabactive{
  color: rgb(0, 122, 255);
  border-bottom: 3px solid rgb(0, 122, 255);
}

/* Style the tab content */
.tabcontent {
  padding: 6px 12px;
  border: 1px solid #ccc;
  border-top: none;
}
</style>
