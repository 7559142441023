<template>
    <div class="joblist" style="padding: 5px">
        <template v-for="month in months" :key="month.month">
            <div
                style="width: 100%; text-align:center; border-bottom: 2px solid black; padding: 10px; background-color: white;color: black">
                <span style="font-size: 30px; font-weight: bold">{{month.month}}</span><br>
                <span style="color: black">Stunden gearbeitet: {{month.hours}}</span>
            </div>
            <template v-for="job in month.jobs" :key="job.id">
            <Archivejob @reloadArchive="$emit('reloadArchive')" :job="job" />
            <div style="height: 2px;background:black;width: 100%"></div>
        </template>
        </template>
    </div>
</template>

<script>
import Archivejob from './Archivejob.vue'
export default {
  name: 'Archivelist',
  props: {
    months: Array
  },
  components: {
    Archivejob
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
