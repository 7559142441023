import { createRouter, createWebHistory } from 'vue-router'
import Jobs from '../views/Jobs.vue'
import Jobdetails from '../views/Jobdetails.vue'
import Chats from '../views/Chats.vue'
import Chat from '../views/Chat.vue'
import Group from '../views/Group.vue'
import Forbidden from '../views/Forbidden.vue'

const routes = [
  {
    path: '/',
    redirect: { name: 'Forbidden' }
  },
  {
    path: '/jobs',
    name: 'Aufträge',
    component: Jobs
  },
  {
    path: '/group/:groupid',
    name: 'Gruppe',
    component: Group
  },
  {
    path: '/chat',
    name: 'Chats',
    component: Chats
  },
  {
    path: '/job/:jobid',
    name: 'Jobdetails',
    component: Jobdetails
  },
  {
    path: '/chat/:chatid',
    name: 'chat',
    component: Chat
  },
  {
    path: '/forbidden',
    name: 'Forbidden',
    component: Forbidden
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
