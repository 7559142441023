<template>
  <div id="nav">
  </div>
  <router-view/>
</template>

<style>
html{
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}
body{
  padding: unset;
  margin: unset;
}
div{
  box-sizing: border-box;
}

</style>
