<template>
  <div class="job" style="padding: 5px; position: relative" :onclick="navigate">
    <span style="display: block; font-size: 30px; font-weight: bold">{{
      job.vertragsbeschreibung
    }}</span>
    <div style="position: relative">
      <div style="display: inline-block; padding-right: 20px">
        <div class="detailrow">
          <i class="far fa-calendar-alt fa-xl" style="padding-right: 5px"></i
          ><span style="font-weight: bold">{{ job.datum_von }}</span
          ><span
            style="font-weight: bold; margin-left: 2px"
            v-if="job.grouped_end"
          >
            - {{ job.grouped_end }}</span
          >
        </div>
        <div class="detailrow">
          <i class="far fa-clock fa-xl" style="padding-right: 5px"></i
          ><span style="font-weight: bold"
            >{{ job.ma_soll_von }}-{{ job.ma_soll_bis }}</span
          >
        </div>
        <div class="detailrow">
          <i class="fas fa-map-marker-alt fa-xl" style="padding-right: 5px"></i
          ><span style="font-weight: bold">{{
            job.einsatzort_beschreibung
          }}</span>
        </div>
      </div>
      <div

        style="
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          right: 5px;
        "
      >
        <span
          style="
            font-weight: bold;
            display: inline-block;
            margin-right: 3px;
          "
          >{{ job.grouped_count }}</span
        >
        <i v-if="job.grouped_count" class="fas fa-layer-group fa-2x" style="vertical-align:middle;padding-right: 5px" />
        <i v-else class="fas fa-angle-right fa-2x" style="vertical-align:middle;padding-right: 10px" />
      </div>
    </div>
  </div>
  <div style="height: 2px;background:lightgray;width: 100%"></div>
</template>

<script>
export default {
  name: 'Job',
  props: {
    job: Object
  },
  methods: {
    navigate: function () {
      console.log('navigation')
      if (this.job.grouped_count) {
        window.location = '/group/' + this.job.auftragsgruppe
      } else {
        window.location = '/job/' + this.job.id
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
.detailrow {
  display: flex;
  align-items: center;
  padding-top: 5px;
  padding-bottom: 5px;
}
</style>
